import React, { Suspense } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Spinner from "../components/ui/Spinner/Spinner";
import lazy from "../libs/preload";
import Providers from "./components/Providers";

const Campaign = lazy(() => import(`../components/pages/campaign`));
const Company = lazy(() => import(`../components/pages/company`));
const Help = lazy(() => import(`../components/pages/help`));
const User = lazy(() => import(`../components/pages/user`));

function App() {
  return (
    <Providers>
      <BrowserRouter>
        <Suspense fallback={<Spinner />}>
          <Switch>
            <Route path="/campaign" render={() => <Campaign />} />
            <Route path="/company" render={() => <Company />} />
            <Route path="/help" render={() => <Help />} />
            <Route path="/user" render={() => <User />} />

            <Redirect to="/user" />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </Providers>
  );
}

requestIdleCallback(() => {
  Campaign.preload();
  Company.preload();
  Help.preload();
  User.preload();
});

export default App;
