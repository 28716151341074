import PropTypes from "prop-types";
import React from "react";
import { ReactComponent as Check } from "../../svg/check.svg";
import { ReactComponent as Close } from "../../svg/close.svg";
import { ReactComponent as Exclamation } from "../../svg/exclamation.svg";
import { ReactComponent as Help } from "../../svg/help.svg";
import styles from "./Alert.module.css";

const Alert = ({ children, onClose, type }) => {
  const skeletonClasses = type === "skeleton" ? `${styles.Skeleton} skeleton` : "";

  let icon;
  if (type === "warning") {
    icon = <Exclamation />;
  } else if (type === "info") {
    icon = <Help />;
  } else if (type === "error") {
    icon = <Close />;
  } else if (type === "success") {
    icon = <Check />;
  }

  return (
    <div className={`${styles.Alert} ${styles[type]} ${skeletonClasses}`}>
      {type !== "skeleton" ? (
        <span className={styles.Close}>
          <Close onClick={onClose} />
        </span>
      ) : null}
      <span className={styles.Icon}>{icon}</span>
      {children}
    </div>
  );
};

Alert.defaultProps = {
  children: "\u00A0",
  onClose: () => {},
  type: "warning",
};

Alert.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
  type: PropTypes.oneOf(["error", "info", "success", "warning", "skeleton"]),
};

export default Alert;
