import PropTypes from "prop-types";
import React, { createContext, useEffect, useMemo, useState } from "react";
import { cssColors } from "../../libs/colors";

export const ThemeContext = createContext({ actions: {}, values: {} });

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState("light");

  const value = useMemo(
    () => ({
      actions: { setTheme },
      values: { theme },
    }),
    [theme]
  );

  useEffect(() => {
    document.body.style = cssColors(theme).reduce(
      (acc, color) => `${acc} --${color[0]}: ${color[1]};`,
      ""
    );
  }, [theme]);

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
};

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ThemeProvider;
