import React from "react";
import colorKeys from "../../../libs/colors";
import styles from "./Spinner.module.css";

const Spinner = () => (
  <div className={styles.Spinner}>
    <div className={styles.Dot} style={{ color: colorKeys.a }} />
    <div className={styles.Dot} style={{ color: colorKeys.p }} />
    <div className={styles.Dot} style={{ color: colorKeys.m }} />
    <div className={styles.Dot} style={{ color: colorKeys.q }} />
    <div className={styles.Dot} style={{ color: colorKeys.i }} />
    <div className={styles.Dot} style={{ color: colorKeys.r }} />
  </div>
);

export default Spinner;
