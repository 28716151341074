import PropTypes from "prop-types";
import React from "react";
import NotificationCenter from "../../components/functional/NotificationCenter";
import ApolloProvider from "../../components/providers/ApolloProvider";
import NotificationProvider from "../../components/providers/NotificationProvider";
import ThemeProvider from "../../components/providers/ThemeProvider";
import UserProvider from "../../components/providers/UserProvider";

function Providers({ children }) {
  return (
    <ThemeProvider>
      <ApolloProvider>
        <UserProvider>
          <NotificationProvider>
            <NotificationCenter />
            {children}
          </NotificationProvider>
        </UserProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
}

Providers.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Providers;
