import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import PropTypes from "prop-types";
import React, { createContext, useCallback, useEffect, useMemo, useState } from "react";

const LOGIN = gql`
  mutation LoginUser($email: String, $password: String) {
    loginUser(email: $email, password: $password) {
      token
    }
  }
`;
const TOKEN = gql`
  {
    loginToken
  }
`;

export const UserContext = createContext({ actions: {}, values: {} });

const UserProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem("token"));

  const user = useMemo(() => {
    if (token) {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
          .join("")
      );

      return JSON.parse(jsonPayload);
    }
    return {};
  }, [token]);

  const userToken = useMemo(() => (token ? token.split(".")[2] : ""), [token]);

  const isLogged = useMemo(() => !!user.id, [user.id]);

  const setTokenAndSave = useCallback((t) => {
    localStorage.setItem("token", t);
    setToken(t);
  }, []);

  const [tokenGraphql] = useLazyQuery(TOKEN, {
    onCompleted(data) {
      setTokenAndSave(data && data.loginToken);
    },
  });

  const [loginGraphql] = useMutation(LOGIN, {
    onCompleted(data) {
      const t = data && data.loginUser && data.loginUser.token;
      localStorage.setItem("token", t);
      setToken(t);
    },
  });

  useEffect(() => {
    if (isLogged === false && !token) {
      tokenGraphql();
    }
  }, [isLogged, token, tokenGraphql]);

  const login = useCallback(
    (email, password) => {
      loginGraphql({ variables: { email, password } });
    },
    [loginGraphql]
  );

  const logout = useCallback(() => {
    localStorage.setItem("token", "");
    setToken("");
  }, []);

  const value = useMemo(
    () => ({
      actions: { login, logout, setToken: setTokenAndSave },
      values: { isLogged, user, token: userToken },
    }),
    [isLogged, login, logout, user, userToken, setTokenAndSave]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserProvider;
