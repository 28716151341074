import cuid from "cuid";
import PropTypes from "prop-types";
import React, { createContext, useCallback, useMemo, useReducer } from "react";
import { objectOmmit } from "../../libs/objects";

export const NotificationContext = createContext({ actions: {}, values: {} });

const initialState = {};

function reducer(state, action) {
  if (action.type === "add") {
    return { ...state, [action.id]: action.value };
  }

  if (action.type === "remove") {
    return objectOmmit(state, action.id);
  }

  return state;
}

const NotificationProvider = ({ children }) => {
  const [notifications, dispatch] = useReducer(reducer, initialState);

  /* eslint-disable react-hooks/exhaustive-deps */
  const removeNotification = React.useCallback((id) => {
    dispatch({ type: "remove", id });
  }, []);

  const addNotification = React.useCallback(
    (notification) => {
      const id = cuid();

      dispatch({ type: "add", id, value: notification });
      setTimeout(removeNotification, 5000, id);
    },
    [removeNotification]
  );

  const addError = useCallback((text) => addNotification({ type: "error", text }));
  const addInfo = useCallback((text) => addNotification({ type: "info", text }));
  const addSuccess = useCallback((text) => addNotification({ type: "success", text }));
  const addWarning = useCallback((text) => addNotification({ type: "warning", text }));

  const value = useMemo(
    () => ({
      actions: { addNotification, removeNotification, addError, addInfo, addSuccess, addWarning },
      values: { notifications },
    }),
    [addNotification, removeNotification, notifications]
  );

  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};

NotificationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NotificationProvider;
