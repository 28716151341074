import React, { useContext } from "react";
import { NotificationContext } from "../providers/NotificationProvider";
import Alert from "../ui/Alert/Alert";

const NotificationCenter = () => {
  const { actions, values } = useContext(NotificationContext);

  return (
    <div style={{ width: "450px", position: "fixed", right: "16px", top: "86px", zIndex: 1 }}>
      {Object.keys(values.notifications).map((notificationId) => (
        <React.Fragment key={notificationId}>
          <Alert
            onClose={() => actions.removeNotification(notificationId)}
            type={values.notifications[notificationId].type}
          >
            {values.notifications[notificationId].text}
          </Alert>
          <div style={{ marginBottom: "16px" }} />
        </React.Fragment>
      ))}
    </div>
  );
};

export default NotificationCenter;
